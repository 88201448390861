<template>
  <div class="login">
    <div class="login-container">
      <div class="page-account">
        <div class="page-account__logo">一键救援</div>
        <Form
          class="page-account__form"
          ref="formInline"
          :model="formInline"
          :rules="ruleInline"
          @keyup.enter="handleSubmit('formInline')"
        >
          <FormItem prop="account">
            <Input
              type="text"
              v-model="formInline.account"
              prefix="ios-contact-outline"
              placeholder="请输入用户名"
              size="large"
            />
          </FormItem>
          <FormItem prop="password">
            <Input
              type="password"
              v-model="formInline.password"
              prefix="ios-lock-outline"
              placeholder="请输入密码"
              size="large"
            />
          </FormItem>
          <!--<FormItem prop="imgcode">-->
          <!--  <div class="code">-->
          <!--    <Input type="text" v-model="formInline.imgcode" prefix="ios-keypad-outline" placeholder="请输入验证码" size="large" />-->
          <!--    <img :src="imgcodesrc" class="pictrue" @click="handleGetCaptcha" />-->
          <!--  </div>-->
          <!--</FormItem>-->
          <FormItem>
            <Button
              type="primary"
              long
              size="large"
              @click="handleSubmit('formInline')"
              class="btn"
              >登录</Button
            >
          </FormItem>
        </Form>
      </div>
    </div>
    <!-- 背景图 -->
    <div class="video-container">
      <div :style="fixStyle" class="filter"></div>
      <video
        :style="fixStyle"
        autoplay
        loop
        muted
        class="fillWidth"
        v-on:canplay="canplay"
      >
        <source
          src="//img-baofun.zhhainiao.com/pcwallpaper_ugc/preview/6391237a9e70bb88c9814644c6814712_preview.mp4"
          type="video/mp4"
        />
        浏览器不支持 video 标签，建议升级浏览器。
      </video>
      <div :style="fixStyle" class="poster hidden" v-if="!videpCanPlay">
        <img
          :style="fixStyle"
          src="https://img-baofun.zhhainiao.com/pcwallpaper_ugc/live/28a4812c4bdb3e83aa4a26baf09ac07d.mp4.jpg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AccountLoginApi } from "@/api/account";
import Setting from "@/config/setting";
import { setCookies } from "@/utils/cookies";
import Config from "@/config";
import { formatFlatteningRoutes } from "@/libs/menus";

export default {
  name: "index",
  data() {
    return {
      imgcodesrc: "",
      formInline: {
        account: "",
        password: "",
        imgcode: "1234",
      },
      ruleInline: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        imgcode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      autoLogin: false,
      videpCanPlay: false,
      fixStyle: "",
    };
  },
  mounted() {
    this.handleGetCaptcha();
    this.background();
    window.addEventListener("keyup", this.handleEnterKey);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleEnterKey);
  },
  methods: {
    handleEnterKey(event) {
      console.log(666);
      if (event.key === "Enter") {
        this.handleSubmit("formInline");
      }
    },
    // 获取验证码
    handleGetCaptcha() {
      this.imgcodesrc =
        Setting.apiBaseURL + "captcha_pro?" + Date.parse(new Date());
    },
    // 提交表单
    handleSubmit(name) {
      const msg = this.$Message.loading({
        content: "登录中...",
        duration: 0,
      });
      this.$refs[name].validate((valid) => {
        if (valid) {
          AccountLoginApi(this.formInline)
            .then(async (res) => {
              msg();
              let data = res.data;
              let expires = this.getExpiresTime(data.expires_time);
              setCookies(Config.tokenStoreName, data.token, expires);
              setCookies("expires_time", data.expires_time, expires);

              this.$store.commit("admin/SET_USERSINFO", data.user_info);
              this.$store.commit("admin/SET_UNIQUEAUTH", data.unique_auth);

              this.$store.commit("menus/setMenusNav", data.menus);

              let arr = formatFlatteningRoutes(this.$router.options.routes);
              this.formatTwoStageRoutes(arr);

              this.$store.commit("menus/setOneLvMenus", arr);
              let routes = formatFlatteningRoutes(data.menus);
              this.$store.commit("menus/setOneLvRoute", routes);

              return this.$router.replace({
                path:
                  this.findFirstNonNullChildren(res.data.menus).path ||
                  "/admin" + "/",
              });
            })
            .catch((error) => {
              console.log(error);
              this.$Message.error(error.msg);
            });
        } else {
          this.$Message.error("请确保必填项不为空哦! (oﾟωﾟo)");
        }
      });
    },
    formatTwoStageRoutes(arr) {
      if (arr.length <= 0) return false;
      const newArr = [];
      const cacheList = [];
      arr.forEach((v) => {
        if (v && v.meta && v.meta.keepAlive) {
          newArr.push({ ...v });
          cacheList.push(v.name);
          this.$store.dispatch("keepAliveNames/setCacheKeepAlive", cacheList);
        }
      });
      return newArr;
    },
    findFirstNonNullChildren(arr) {
      // 如果数组为空，返回null
      if (!arr || arr.length === 0) {
        return null;
      }
      // 找到第一个对象
      const firstObj = arr[0];
      // 如果第一个对象没有children属性，返回该对象
      if (!firstObj.children) {
        return firstObj;
      }

      // 如果第一个对象的children属性是数组，
      // 递归查找children属性中的第一个非null children属性
      if (Array.isArray(firstObj.children)) {
        return this.findFirstNonNullChildren(firstObj.children);
      }
      // 如果数组中没有非null children属性，返回null
      return null;
    },
    getExpiresTime(expiresTime) {
      let nowTimeNum = Math.round(new Date() / 1000);
      let expiresTimeNum = expiresTime - nowTimeNum;
      return parseInt(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24);
    },
    // 是否播放
    canplay() {
      this.videpCanPlay = true;
    },
    // 背景图
    background() {
      window.onresize = () => {
        const windowWidth = document.body.clientWidth;
        const windowHeight = document.body.clientHeight;
        const windowAspectRatio = windowHeight / windowWidth;
        let videoWidth;
        let videoHeight;
        if (windowAspectRatio < 0.5625) {
          videoWidth = windowWidth;
          videoHeight = videoWidth * 0.5625;
          this.fixStyle = {
            height: windowWidth * 0.5625 + "px",
            width: windowWidth + "px",
            "margin-bottom": (windowHeight - videoHeight) / 2 + "px",
            "margin-left": "initial",
          };
        } else {
          videoHeight = windowHeight;
          videoWidth = videoHeight / 0.5625;
          this.fixStyle = {
            height: windowHeight + "px",
            width: windowHeight / 0.5625 + "px",
            "margin-left": (windowWidth - videoWidth) / 2 + "px",
            "margin-bottom": "initial",
          };
        }
      };
      window.onresize(undefined);
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "zh64hmengquruantangt";
  src: url("./../../assets/font/zh64hmengquruantangt.ttf");
  font-weight: normal;
  font-style: normal;
}

.login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  height: 100vh;
  margin: 0 auto !important;
  z-index: 1;

  .page-account {
    padding: 10px 20px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    &__logo {
      text-align: center;
      font-size: 32px;
      color: white;
      font-family: "zh64hmengquruantangt";
      margin-bottom: 10px;
      background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &__form {
      .ivu-input {
        background-color: transparent;
        color: white;
      }
      .ivu-icon {
        font-size: 22px;
        color: white;
      }
      .code {
        display: flex;
        align-items: center;
        justify-content: center;
        .pictrue {
          height: 40px;
          margin-left: 5px;
          border-radius: 4px;
        }
      }
    }
  }

  &_auto-login {
    margin-bottom: 24px;
    text-align: left;
    color: #fff;
  }
}

.demo-login-captcha .ivu-btn {
  padding: 0;
}
.demo-login-captcha .ivu-btn img {
  height: 28px;
  position: relative;
  top: 4px;
}

// 背景
.video-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  z-index: -8888;

  .poster img,
  video {
    z-index: 0;
    position: absolute;
  }

  .filter {
    z-index: 1;
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
  }
}
</style>
